<template>
  <div class="goods-gq">
    <van-nav-bar
      :fixed="true"
      left-arrow
      placeholder
      @click-left="onClickLeft"
      :title="$t('Home.WithdrawSu.Withdraw')"
    />
    <!-- 头部盒子 -->
    <div class="Withdrawal">
      <div class="amount">
        <div class="amount-wi">{{ $t("Home.WithdrawSu.Withdrawal") }}</div>
        <div class="amount-re">
          {{ $t("Home.WithdrawSu.WithdrawalRe") }}{{ "2.0%" }}
        </div>
      </div>
      <div class="rp-in">
        <label>{{ $t("Home.RP") }}</label>
        <input
          v-model="money"
          type="text"
          :placeholder="$t('Home.WithdrawSu.Input')"
        />
      </div>
      <div class="Balance">
        <div class="Balance-ba">{{ $t("Home.WithdrawSu.Balance") }}：</div>
        <div class="Balance-rp">{{ $t("Home.RP") }}&nbsp;{{ assets }}</div>
        <div @click="WithdrawAll" class="Balance-wi">
          {{ $t("Home.WithdrawSu.WithdrawAll") }}
        </div>
      </div>
      <div class="Balance-rp">
        Withdrawal limit today：&nbsp;{{ payout_off }}
      </div>
    </div>
    <!-- 中间ibput框 -->
    <div class="mobile">
      <!-- <input v-model="Bank" type="text" :placeholder="$t(mobileData[1].txt)" />
      <input v-model="Owned" type="text" :placeholder="$t(mobileData[2].txt)" /> -->
      <!-- <input
        v-model="Account"
        type="text"
        :placeholder="$t(mobileData[3].txt)"
      /> -->
      <div class="pull-down">
        <!-- <span>{{ $t('My.PersonalInformation.BankAccounts.BankName') }}</span> -->
        <van-dropdown-menu>
          <van-dropdown-item v-model="Account" :options="optionData" />
        </van-dropdown-menu>
      </div>
      <!-- <input
        v-model="Transaction"
        type="password"
        :placeholder="$t(mobileData[4].txt)"
      /> -->
      <input
        v-for="(item, index) in newFormList"
        :key="index"
        :type="item.type"
        :placeholder="item.placeholder"
        v-model="newForm[item.key]"
      />
    </div>
    <!-- 底部按钮 -->
    <div @click="withdrawal" class="Withdrawim">
      {{ $t("Home.WithdrawSu.Withdrawim") }}
    </div>
    <!-- <div class="Binding" @click="BindingFun" v-show="binding">
      {{ $t('Home.WithdrawSu.Binding') }}
    </div> -->
  </div>
</template>
<script>
import Vue from "vue";
import { NavBar, Dialog, Toast } from "vant";
import { REQUEST_API } from "@/http/api";
import md5 from "js-md5";
Vue.use(NavBar);
import { DropdownMenu, DropdownItem } from "vant";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
export default {
  name: "Withdraw",
  components: {
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  data() {
    return {
      // 运算金额
      operation: "",
      // 按钮显示
      binding: false,
      // 资产
      assets: "",
      // 提现额度
      payout_off: "",
      // 银行数据
      bank: "",
      // 充值金额
      money: "",
      // 手机号码
      Mobile: "",
      // 银行卡号
      Bank: "",
      // 区号
      Area: "",
      // 所属银行
      Owned: "",
      // 用户名
      Account: "",
      // 交易密码
      Transaction: "",
      // 用户信息
      userData: "",
      // uid
      uid: "",
      // Mobile
      mobileData: [
        { txt: "Home.WithdrawSu.Mobile" },
        { txt: "Home.WithdrawSu.Bank" },
        { txt: "Home.WithdrawSu.Owned" },
        { txt: "Home.WithdrawSu.Account" },
        { txt: "Home.WithdrawSu.Transaction" },
      ],
      // 轻提示
      TipsData: [
        { txt: "Home.WithdrawSu.Pleaseamount" },
        { txt: "Home.WithdrawSu.Pleasephone" },
        { txt: "Home.WithdrawSu.Pleasecard" },
        { txt: "Home.WithdrawSu.Pleasebank" },
        { txt: "Home.WithdrawSu.Pleasename" },
        { txt: "Home.WithdrawSu.Pleasepassword" },
      ],
      // BCA.FT Bank Central Asia
      // BNI.FT Bank Negara Indonesia
      // MDR.FT Bank Mandiri
      // BRI.FT Bank Rakyat Indonesia
      // Virtual Account (VA)
      // 银行代码
      // BNI.VA Bank Negara Indonesia
      // BMI.VA Maybank Indonesia
      // MDR.VA Bank Mandiri
      // PTB.VA（省略va后为代码
      // ----------------------------------------
      // BCA Bank Central Asia
      // MDR Bank Mandiri
      // BRI Bank Rakyat Indonesia
      // BNI Bank Negara Indonesia
      // PTB Bank Permata
      // BMI Maybank Indonesia
      // BAG Bank Artha Graha
      // BBKP Bank Bukopin
      // BCW Bank Commonwealth
      // BDM Bank Danamon
      // BMG Bank Mega
      // BMP Bank Maspion
      // BMTK Bank Mestika
      // BPN Bank Panin
      // BSMT Bank Sumut
      // BSM Bank Sinar Mas
      // BTPN Bank Btpn
      // HSBCI HSBC Indonesia
      // OCBCI OCBC Indonesia
      // UOBI UOB Indonesia
      // CIMBN CIMB Niaga
      // ----------------------------------------
      optionData: [
        {
          text: "Bank Central Asia",
          value: "BCA.FT",
          code: "BCA.VA",
        },
        {
          text: "Bank Negara Indonesia",
          value: "BNI.FT",
          code: "BNI.VA",
        },
        {
          text: "Bank Mandiri",
          value: "MDR.FT",
          code: "MDR.VA",
        },
        // {
        //   text: 'Bank Rakyat Indonesia',
        //   value: 'BRI.FT',
        //   code: 'BRI.VA',
        // },

        {
          text: "Bank Permata",
          value: "PTB.FT",
          code: "PTB.VA",
        },
        {
          text: "Maybank Indonesia",
          value: "BMI.FT",
          code: "BMI.VA",
        },

        // {
        //   text: 'Bank Artha Graha',
        //   value: 'BAG.FT',
        //   code: 'BAG.VA',
        // },
        // {
        //   text: 'Bank Bukopin',
        //   value: 'BBKP.FT',
        //   code: 'BBKP.VA',
        // },
        // {
        //   text: 'Bank Commonwealth',
        //   value: 'BCW.FT',
        //   code: 'BCW.VA',
        // },
        // {
        //   text: 'Bank Danamon',
        //   value: 'BDM.FT',
        //   code: 'BDM.VA',
        // },
        // {
        //   text: 'Bank Mega',
        //   value: 'BMG.FT',
        //   code: 'BMG.VA',
        // },
        // {
        //   text: 'Bank Maspion',
        //   value: 'BMP.FT',
        //   code: 'BMP.VA',
        // },
        // {
        //   text: 'BMTK Bank Mestika',
        //   value: 'BMTK.FT',
        //   code: 'BMTK.VA',
        // },
        // {
        //   text: 'Bank Panin',
        //   value: 'BPN.FT',
        //   code: 'BPN.VA',
        // },
        // {
        //   text: 'Bank Sumut',
        //   value: 'BSMT.FT',
        //   code: 'BSMT.VA',
        // },
        // {
        //   text: 'Bank Sinar Mas',
        //   value: 'BSM.FT',
        //   code: 'BSM.VA',
        // },
        // {
        //   text: 'Bank Btpn',
        //   value: 'BTPN.FT',
        //   code: 'BTPN.VA',
        // },
        // {
        //   text: 'HSBC Indonesia',
        //   value: 'HSBCI.FT',
        //   code: 'HSBCI.VA',
        // },
        // {
        //   text: 'OCBC Indonesia',
        //   value: 'OCBCI.FT',
        //   code: 'OCBCI.VA',
        // },
        // {
        //   text: 'UOB Indonesia',
        //   value: 'UOBI.FT',
        //   code: 'UOBI.VA',
        // },
        // {
        //   text: 'CIMB Niaga',
        //   value: 'CIMBN.FT',
        //   code: 'CIMBN.VA',
        // },
      ],
      /**
       * TO DO
       * 不想动他之前的代码，就直接加在这下面算了
       */
      newForm: {
        account: "",
        card_no: "",
        tel: "",
        id_card: "",
        // sub_bank: '',
        bank_code: "",
      },
      newFormList: [
        {
          label: "账户名",
          key: "account",
          type: "text",
          placeholder: this.$t("Home.WithdrawSu.Pleasename"),
        },
        {
          label: "身份证",
          key: "id_card",
          type: "text",
          placeholder: this.$t("Home.WithdrawSu.idCard"),
        },
        {
          label: "卡号",
          key: "card_no",
          type: "number",
          placeholder: this.$t("Home.WithdrawSu.Bank"),
        },
        {
          label: "手机号",
          key: "tel",
          type: "number",
          placeholder: this.$t("Home.WithdrawSu.Pleasephone"),
        },
      ],
    };
  },
  mounted() {
    Toast.allowMultiple();
    this.userData = JSON.parse(localStorage.getItem("user-info"));
    this.uid = this.userData[1].data.player.uid;
    if (this.userData[1].data.player.bank_card === "") {
      /**
       * TO DO
       * 提示未绑定银行卡
       */
      // Toast(this.$t('Home.WithdrawSu.Unbound'))
      this.binding = true;
    }
    // 调用银行API
    this.assetsFun();
    this.bankFun();
  },
  created() {
    this.Account = this.optionData[0].value;
    this.newForm.bank_code = this.optionData[0].code;
  },
  methods: {
    // 跳转绑定银行卡
    BindingFun() {
      this.$router.push({ name: "Account" });
    },
    // 全部选中
    WithdrawAll() {
      if (this.payout_off * 1 >= this.assets * 1) {
        this.money = this.assets;
      }
      if (this.payout_off * 1 < this.assets * 1) {
        this.money = this.payout_off;
      }
    },
    // 资产API
    assetsFun() {
      var params = {
        cmd: "202445",
        uid: this.uid,
      };
      REQUEST_API(params).then((res) => {
        console.log(res);
        this.assets = "";
        this.$store.commit("SET_MONEY_INFO", res.data.notifyList[0].data);
        this.operation = this.$store.state.user.moneyObj.diamond;
        this.assets = deciTurnMoney(this.$store.state.user.moneyObj.diamond);
        this.payout_off = deciTurnMoney(
          this.$store.state.user.moneyObj.payout_off
        );
      });
    },
    // 提现API
    withdrawal() {
      this.optionData.forEach((_) => {
        if (this.Account === _.value) {
          this.newForm.bank_code = _.code;
        }
      });
      /**
       * TO DO
       * 将就有些表单验证能用的就用吧
       */
      // 表单验证
      if (this.money == "") {
        Toast(this.$t(this.TipsData[0].txt));
        return;
      }
      // if (this.Bank == '') {
      //   Toast(this.$t(this.TipsData[2].txt))
      //   return
      // }
      // if (this.Owned == '') {
      //   Toast(this.$t(this.TipsData[3].txt))
      //   return
      // }
      if (this.Account == "") {
        Toast(this.$t(this.TipsData[4].txt));
        return;
      }
      // if (this.Transaction == '') {
      //   Toast(this.$t(this.TipsData[5].txt))
      //   return
      // }
      // if (this.money > this.operation) {
      //   Toast(this.$t('Home.WithdrawSu.Insufficient'))
      //   return
      // }

      let count = 0;
      for (let key in this.newForm) {
        if (this.newForm[key]) {
          count++;
        }
      }
      if (count < 4) {
        console.log(count);
        Toast(this.$t("Hint.notInput")); // 输入内容不完整
        return false;
      }
      /**
       * TO DO
       * 未绑定银行卡
       */
      // if (this.userData[1].data.player.bank_card === '') {
      //   Toast(this.$t('Hoem.WithdrawSu.Unbound'))
      //   this.binding = true
      //   return
      // }
      var data = "";
      for (let i = 0; i < this.bank.length; i++) {
        if (this.Owned == this.bank[i].name) {
          data = this.bank[i];
        }
      }
      if (!/^[0-9]\d*$/.test(this.money)) {
        Toast(this.$t("Home.WithdrawSu.Pleaseint"));
        return;
      }
      var params = {
        // cmd: '201013',
        cmd: "201022", // 20201130 提现新接口
        uid: this.uid,
        amount: this.money,

        /**
         * TO DO
         * 没改他的东西，干脆将就用了
         */
        bank_code: this.newForm.bank_code, // 银行code
        sub_bank: this.Account, // 支行名称

        account: this.newForm.account, // 账户名
        card_no: this.newForm.card_no, // 卡号
        id_card: this.newForm.id_card, // 身份证
        tel: this.newForm.tel, // 手机号

        // currency: data.currency,
        // name: this.Account,
        // iban: this.Bank,
        // item_description: '',
        // pay_pwd: md5(this.Transaction),
      };
      REQUEST_API(params).then((res) => {
        if (!res.data.sc) {
          if (res.data.notifyList[0].data.payout.code === 200) {
            Toast(this.$t("Home.WithdrawSu.Successful"));
            this.assetsFun();
            this.init();
          } else {
            Toast(res.data.notifyList[0].data.payout.msg);
          }
        } else {
          Toast(this.$t("Hint.failed"));
        }
        // if (res.data.sc === 2207) {
        //   Toast(this.$t('Home.WithdrawSu.PasswordError'))
        // } else {
        //   Toast(this.$t('Home.WithdrawSu.Successful'))
        //   this.assetsFun()
        // }
      });
    },
    // 银行API
    bankFun() {
      var params = {
        cmd: 201017,
      };
      REQUEST_API(params).then((res) => {
        this.bank = res.data.notifyList[0].data.banks;
      });
    },
    // 返回按钮
    onClickLeft() {
      this.$router.go(-1);
    },
    /** 其他 **/
    init() {
      this.money = "";
      this.Account = "";
      this.newForm = {
        account: "",
        card_no: "",
        tel: "",
        id_card: "",
        // sub_bank: '',
        bank_code: "",
      };
    },
  },
};
</script>
<style lang="less">
.goods-gq {
  width: 100vw;
  height: 100vh;
  background-color: #dddbdb;
  .pull-down {
    // width: calc(100% - 32px);
    // padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      font-size: 0.875rem;
      color: #646566;
    }
    .van-dropdown-menu {
      // width: 50%;
      font-size: 1rem;
      .van-dropdown-menu__bar {
        // width: 90%;
        box-shadow: none;
        .van-dropdown-menu__title {
          width: 100%;
          // padding: 0;
          padding: 1px 8px 0 2px;
          text-align: left;
          color: #9a9a9a;
        }
      }
    }
  }
}
// 头部盒子样式
.Withdrawal {
  width: 90vw;
  margin: auto;
  margin-top: 1rem;
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 0.5rem;
  label {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
  input {
    width: 70%;
    font-size: 1rem;
    border: 0px;
  }
  .rp-in,
  .amount {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .Balance-rp {
    margin-right: 1rem;
  }
  .Balance-ba,
  .Balance-rp {
    font-size: 0.7rem;
    color: #9a9a9a;
  }
  .Balance-wi {
    font-size: 0.8rem;
  }
}
.amount {
  width: 90%;
  display: flex;
  .amount-wi {
    margin-right: 0.5rem;
    font-size: 1rem;
    padding: 0.1rem 0.3rem;
  }
  .amount-re {
    border: 1px solid orange;
    color: orange;
    text-align: center;
    border-radius: 10px;
    padding: 0.1rem 0.3rem;
    font-size: 0.7rem;
  }
}
.Balance {
  width: 90%;
  display: flex;
}
// 中间框
.mobile {
  width: 90vw;
  margin: auto;
  margin-top: 0.5rem;
  border-radius: 5px;
  background-color: white;
  border: 1px solid white;
  padding: 0.5rem;
  .mobile-Area {
    display: flex;
  }
  .Area {
    width: 30%;
  }
  input {
    width: 90%;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem;
    color: #9a9a9a;
    border: 0;
  }
}
// 底部按钮
.Withdrawim {
  width: calc(90vw + 1rem);
  height: 2rem;
  line-height: 2rem;
  background-color: black;
  color: white;
  margin: auto;
  margin-top: 1rem;
  text-align: center;
  border-radius: 5px;
}
.Binding {
  width: 90vw;
  margin: auto;
  margin-top: 0.8rem;
  text-align: right;
  color: blue;
  font-size: 0.8rem;
}
</style>